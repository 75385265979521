/** @format */

import "./customStyle/main.scss";
import React, { lazy, Suspense, useState, useRef, useEffect } from "react";
// import ErrorPage from "./components/ErrorPage/ErrorPage";
import GlobalContextProvider from "./utils/contexts";
// import LoadPage from "./components/LoadPage/LoadPage";
import SOCKET_URL from "./socket";
import { useMediaQuery } from "react-responsive";
import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { io } from "socket.io-client";
import { getAuthUser } from "./utils/utils";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import RequiredAuth from "./components/router/RequiredAuth";
import RequiredCoach from "./components/router/RequiredCoach";
import RequiredAdmin from "./components/router/RequireAdmin";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import RequiredNormalUser from "./components/router/RequiredNormalUser";
import audio from "../src/assets/audio/Simple notification.mp3";
import WeekendClass from "./components/Orders/WeekendClasses/WeekendClass.jsx";
import ChessBootCampOrder from "./components/Orders/ChessBootCampOrder/ChessBootCampOrder.jsx";
import BootCampChessOrder from "./components/Orders/BootCampChessOrder/BootCampChessOrder.js";
import AllSchoolOrder from "./components/Orders/AllSchoolOrder/AllSchoolOrder.jsx";
import SchoolOrder590 from "./components/Orders/SchoolOrder590/SchoolOrder590.jsx";
import { RedirectToInstagram } from "./components/RedirectToInstagram.js";

const BatchOrder = lazy(() =>
  import("./components/Orders/BatchOrder/BatchOrder.jsx")
);
const AdminDemo = lazy(() =>
  import("./components/Admin/Dashboard/AdminDemo.jsx")
);
const AdminLayout = lazy(() => import("./components/Layout/AdminLayout"));
const AdminBootCamp = lazy(() =>
  import("./components/Admin/Dashboard/AdminBootCamp")
);
const Analytics = lazy(() => import("./components/Admin/Dashboard/Analytics"));
const CoachApproval = lazy(() =>
  import("./components/Admin/Dashboard/CoachApproval")
);
const SupportTicket = lazy(() =>
  import("./components/Admin/Dashboard/SupportTicket")
);
const Bootcamp = lazy(() => import("./components/Admin/Dashboard/Bootcamp"));
const Adminwhatsapp = lazy(() =>
  import("./components/Admin/Dashboard/Adminwhatsapp")
);
const AdminRefferal = lazy(() =>
  import("./components/Admin/Dashboard/AdminRefferal")
);
const AdminSendMsgWA = lazy(() =>
  import("./components/Admin/Dashboard/AdminSendMsgWA.jsx")
);
const AdminLeads = lazy(() =>
  import("./components/Admin/Dashboard/AdminLeads")
);
const GoodiesTable = lazy(() =>
  import("./components/Admin/Dashboard/GoodiesTable")
);
const AdminBatch = lazy(() =>
  import("./components/Admin/Dashboard/AdminBatch")
);
const AdminStud = lazy(() => import("./components/Admin/Dashboard/AdminStud"));
const Payment = lazy(() => import("./components/Admin/Dashboard/Payment"));
const CurrentPayment = lazy(() =>
  import("./components/Admin/Dashboard/CurrentPayment.jsx")
);

const CoachBatches = lazy(() =>
  import("./components/Dashboard/CoachDashboard/Components/CoachBatches")
);
const adminDashboard = lazy(() =>
  import("./components/Admin/Dashboard/AdminDashboard")
);
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const LayoutNav = lazy(() => import("./components/Layout/LayoutNav"));
const TournamentLayout = lazy(() =>
  import("./components/Layout/TournamentLayout")
);
const Home = lazy(() => import("./components/Home/Home"));
const BootCampCategories = lazy(() =>
  import("./components/Bootcamp/BootcapCategories")
);
const ChessBootCamp = lazy(() =>
  import("./components/ChessBootCamp/ChessBootCamp")
);
const MasteryChessBootCamp = lazy(() =>
  import("./components/ChessBootCamp/MasteryChessBootcamp")
);
const BecomeCoach = lazy(() =>
  import("./components/BecomeBootcamp/BecomeCoach")
);
const BecomeCoachForm = lazy(() =>
  import("./components/BecomeBootcamp/BecomeCoachForm")
);
const Learning = lazy(() => import("./components/Learning1v1/Learning1v1"));
const Filter1v1 = lazy(() => import("./components/Learning1v1/Filter1v1"));
const ChessPlay = lazy(() => import("./components/ChessPlay/ChessPlay"));
const Settings = lazy(() => import("./components/Settings/Settings"));
const RaisedTicket = lazy(() =>
  import("./components/RaisedTicket/RaisedTicket")
);
const Order = lazy(() =>
  import("./components/Orders/BootcampOrder/BootcampOrder")
);
const LearningOrder = lazy(() =>
  import("./components/Orders/LearningOrder/LearningOrder")
);
const CategoryTournament = lazy(() =>
  import("./components/Tournament/categoryTournaments2")
);

const CoachOrder = lazy(() =>
  import("./components/Orders/CoachOrder/CoachOrder")
);
const OrderFinal = lazy(() =>
  import("./components/Orders/OrderFinal/OrderFinal")
);

const Notification = lazy(() =>
  import("./components/Notifications/Notifications")
);
const BootcampOrderFinal = lazy(() =>
  import("./components/Orders/OrderFinal/BootcampOrderFinal")
);
const OrderList = lazy(() => import("./components/Orders/OrderList/OrderList"));
const Orderhistory = lazy(() =>
  import("./components/Orders/Orderhistory/Orderhistory")
);
const All1v1order = lazy(() =>
  import("./components/Orders/All1v1order/All1v1order")
);
const Profile = lazy(() => import("./components/Dashboard/Profile"));
const AdminDashboard = lazy(() =>
  import("./components/Admin/Dashboard/AdminDashboard")
);

const TransactionDetails = lazy(() =>
  import("./components/TransactionDetails/TransactionDetails")
);
const CoachDashboard = lazy(() =>
  import("./components/Dashboard/CoachDashboard/CoachDashboard")
);
const CoachGig = lazy(() => import("./components/CoachGig/CoachGig"));
const ResetPassword = lazy(() => import("./components/ResetPassword/reset"));

const Terms = lazy(() => import("./components/Terms/Terms"));

const Privacy = lazy(() => import("./components/Privacy/Privacy"));

const Refund = lazy(() => import("./components/Refund/Refund"));

const AboutUs = lazy(() => import("./components/AboutUs/AboutUs"));

const ContactUs = lazy(() => import("./components/ContactUs/ContactUs"));

const TournamentInfo = lazy(() =>
  import("./components/Tournament/TournamentInfo")
);
// start
const Affiliates = lazy(() => import("./components/Affiliates/Affiliates"));

const GroupClassOrder = lazy(() =>
  import("./components/Orders/GroupClassOrder/GroupClassOrder")
);

const CoachBootcampDashboard = lazy(() =>
  import(
    "./components/Dashboard/CoachDashboard/Components/CoachBootcampDashboard.jsx"
  )
);
const UserBootcampDashboard = lazy(() =>
  import("./components/Dashboard/Components/UserBootcampDashboard")
);
const MainMeetPage = lazy(() => import("./components/MeetPage/MainMeetPage"));

const Matchhistory = lazy(() =>
  import("./components/MatchHistory/Matchhistory")
);

const CreateBootCamp = lazy(() =>
  import("./components/CreateBootCamp/CreateBootCamp")
);

const LoadPage = lazy(() => import("./components/LoadPage/LoadPage"));
const SchoolOrder = lazy(() =>
  import("./components/Orders/SchoolOrder/SchoolOrder")
);

const ErrorPage = lazy(() => import("./components/ErrorPage/ErrorPage"));

const Batch = lazy(() => import("./components/Admin/Dashboard/Batch"));

const Attendence = lazy(() =>
  import("./components/Admin/Dashboard/Attendence")
);

function App() {
  //react media queries------------------
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  //thunder points
  // // setting thunder points temp data
  // const currentBalance = 0;
  // const [thunderPoints, setThunderPoints] = useState(currentBalance);

  const [currentUser, setCurrentUser] = useState({});

  const [coach, setCoach] = useState(false);
  const [coachData, setCoachData] = useState({});
  const [coachLoading, setCoachLoading] = useState(true);
  const [admin, setAdmin] = useState(false);
  const socket = useRef(null);

  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENTID,
    currency: "USD",
    intent: "capture",
    "disable-funding": "card",
  };
  //coach is setting the state for Coach Dashboard

  //1. if coach is true -> replace the default routes with coach dashboard routes. else render default routes

  //this is global state which will store all details of the authenticated user...which..can..be then used throughout website
  const [auth, setAuth] = useState(getAuthUser());

  //filter for which game's coach should be displayed
  const [filter, setFilter] = useState("all");

  //set top banner
  const [topBanner, setTopBanner] = useState("chess");

  //all chat list
  const [chatListData, setChatListData] = useState([]);

  //open chat module state
  const [open, setOpen] = useState(false);
  // open message box state
  const [openChat, setOpenChat] = useState(false);
  //current chatting
  const [chattingWith, setChattingWith] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [matchId, setMatchId] = useState(null);
  const [roundTime, setRoundTime] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [registrationId, setRegistrationId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const [round, setRound] = useState(null);
  const [ChessServerConn, setChessServerConn] = useState(false);
  const [GameEnded, setGameEnded] = useState(false);
  const [matchHistoryId, setMatchHistoryId] = useState(null);

  // //full screen mode
  // useEffect(() => {
  //   function onFullscreenChange() {
  //     setFullScreenMode(Boolean(document.fullscreenElement));
  //   }

  //   document.addEventListener('fullscreenchange', onFullscreenChange);

  //   return () => document.removeEventListener('fullscreenchange', onFullscreenChange);

  // }, [])
  // socket
  useEffect(() => {
    if (!socket.current) {
      socket.current = io(SOCKET_URL, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: "Infinity",
        reconnectionDelay: 1000,
      });
    }
    var dash = localStorage.getItem("dashboard");
    if (dash && dash === "coach") {
      setCoach(true);
    }
    if (dash && dash === "admin") {
      setAdmin(true);
    }
  }, []);

  function updateChatListData(chat) {
    setChatListData((prevChats) => {
      var previousChat = prevChats.find((ch) => ch._id === chat._id);
      if (previousChat) {
        var newChats = [...prevChats];
        newChats = newChats.filter((ch) => ch._id !== chat._id);
        newChats = [chat, ...newChats];
        return newChats;
      } else {
        return [chat, ...prevChats];
      }
    });
  }
  // play notification sound handler
  const handlePlayNotification = () => {
    new Audio(audio).play();
  };
  useEffect(() => {
    if (socket.current && auth?._id) {
      socket.current.emit("loggedin", auth);
    }
    if (socket.current) {
      socket.current.on("updateUserList", (users) => {
        setOnlineUsers(users);
      });
      socket.current.on("receiveMessage", (message) => {
        updateChatListData(message.chat);
        if (auth && message.senderId !== auth?._id) {
          handlePlayNotification();
        }
      });
    }
  }, [auth, socket]);

  //global context which will be available throughout the website
  const context_to_be_sent = {
    setAuth,
    auth,
    setFilter,
    filter,
    setChatListData,
    chatListData,
    setOpen,
    chattingWith,
    setChattingWith,
    openChat,
    setOpenChat,
    currentUser,
    setCurrentUser,
    coach,
    setCoach,
    coachData,
    setCoachData,
    coachLoading,
    setCoachLoading,
    admin,
    setAdmin,
    socket,
    onlineUsers,
    open,
    loading,
    setLoading,
    topBanner,
    setTopBanner,
    matchId,
    setMatchId,
    roundTime,
    setRoundTime,
    timezone,
    setTimezone,
    registrationId,
    setRegistrationId,
    schoolId,
    setSchoolId,
    tournamentId,
    setTournamentId,
    round,
    setRound,
    ChessServerConn,
    setChessServerConn,
    GameEnded,
    setGameEnded,
    matchHistoryId,
    setMatchHistoryId,
  };
  return (
    <>
      <PayPalScriptProvider options={initialOptions}>
        <BrowserRouter>
          <ScrollToTop />
          <GlobalContextProvider context={context_to_be_sent}>
            <Suspense fallback={<LoadPage />}>
              <Routes>
                <Route
                  path="/summerchessclasses"
                  element={<GroupClassOrder />}
                />
                <Route path="/weekendclass" element={<WeekendClass />} />
                <Route path="/chessBootCamp" element={<ChessBootCampOrder />} />
                <Route
                  path="/chessBootCampPayment"
                  element={<BootCampChessOrder />}
                />
                <Route path="/hadasgroupclasses" element={<SchoolOrder />} />
                <Route path="/schoolPayment" element={<AllSchoolOrder />} />
                <Route
                  path="admin-dashboard"
                  element={
                    <RequiredAdmin>
                      <AdminDashboard />
                    </RequiredAdmin>
                  }
                />

                <Route
                  path="/batch"
                  element={
                    <RequiredAdmin>
                      <Batch />
                    </RequiredAdmin>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <AdminLayout
                      setCurrentUser={setCurrentUser}
                      coach={coach}
                      desktop={isDesktopOrLaptop}
                      setCoach={setCoach}
                      coachData={coachData}
                      setCoachData={setCoachData}
                    />
                  }
                >
                  <Route path="/admin/dashboard" element={<Analytics />} />
                  <Route
                    path="/admin/coach-approval"
                    element={<CoachApproval />}
                  />
                  <Route
                    path="/admin/support-ticket"
                    element={<SupportTicket />}
                  />
                  <Route path="/admin/bootcamp" element={<Bootcamp />} />
                  <Route path="/admin/1v1" element={<All1v1order />} />
                  <Route path="/admin/whatsapp" element={<Adminwhatsapp />} />
                  <Route path="/admin/refferal" element={<AdminRefferal />} />
                  <Route path="/admin/messages" element={<AdminSendMsgWA />} />
                  <Route path="/admin/leads" element={<AdminLeads />} />
                  <Route path="/admin/goodies" element={<GoodiesTable />} />
                  <Route path="/admin/batch" element={<AdminBatch />} />
                  <Route path="/admin/students" element={<AdminStud />} />
                  <Route path="/admin/payment" element={<Payment />} />
                  <Route path="/admin/attendence" element={<Attendence />} />

                  <Route
                    path="/admin/currentpayment"
                    element={<CurrentPayment />}
                  />
                  <Route path="/admin/demo" element={<AdminDemo />} />
                  {/* <Route
                    path="/chessplay/puzzles/:method"
                    element={<ChessPlay />}
                  />
                  <Route
                    path="/chessplay/puzzles/:method/:date"
                    element={<ChessPlay />}
                  /> */}
                </Route>
                <Route
                  path="/"
                  element={
                    <TournamentLayout
                      setCurrentUser={setCurrentUser}
                      coach={coach}
                      desktop={isDesktopOrLaptop}
                      setCoach={setCoach}
                      coachData={coachData}
                      setCoachData={setCoachData}
                    />
                  }
                >
                  <Route path="/chessplay/:method?" element={<ChessPlay />} />
                  <Route
                    path="/chessplay/puzzles/:method"
                    element={<ChessPlay />}
                  />
                  <Route
                    path="/chessplay/puzzles/:method/:date"
                    element={<ChessPlay />}
                  />
                </Route>
                <Route
                  path="/"
                  element={
                    <LayoutNav
                      setCurrentUser={setCurrentUser}
                      coach={coach}
                      desktop={isDesktopOrLaptop}
                      setCoach={setCoach}
                      coachData={coachData}
                      setCoachData={setCoachData}
                    />
                  }
                >
                  <Route path="*" element={<ErrorPage />} />
                  <Route index element={<Home />} />
                  <Route
                    path="/bootcampcategory/:category/bootcamp/:id"
                    element={<ChessBootCamp />}
                  />
                  <Route
                    path="/bootcampcategory/mastery/bootcamp/:id"
                    element={<MasteryChessBootCamp />}
                  />
                  <Route
                    path="bootcampcategory/:category"
                    element={<BootCampCategories />}
                  />
                  <Route path="learning/:id" element={<Learning />} />
                  <Route path="learning1v1" element={<Filter1v1 />} />
                  <Route path="/chessplay/:method?" element={<ChessPlay />} />

                  <Route path="/order/id/:id/:status" element={<Order />} />
                  <Route
                    path="orderlist"
                    element={
                      <RequiredCoach>
                        <OrderList />
                      </RequiredCoach>
                    }
                  />
                  <Route
                    path="admin-dashboard/r6"
                    element={
                      <RequiredAdmin>
                        <CoachBatches />
                      </RequiredAdmin>
                    }
                  />
                  <Route
                    path="learningorder/id/:id/plan/:plan/:status"
                    element={
                      <RequiredAuth>
                        <LearningOrder />
                      </RequiredAuth>
                    }
                  />

                  <Route path="coachorder/:id" element={<CoachOrder />} />
                  <Route
                    path="category-tournament/:status"
                    element={<CategoryTournament />}
                  />
                  <Route
                    path="settings"
                    element={
                      <RequiredAuth>
                        <Settings />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="ticket"
                    element={
                      <RequiredAuth>
                        <RaisedTicket />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="orderfinal/:id/:status"
                    element={
                      <RequiredAuth>
                        <OrderFinal />
                      </RequiredAuth>
                    }
                  />
                  <Route path="notification" element={<Notification />} />
                  <Route
                    path="bootcamporderfinal/:id"
                    element={
                      <RequiredAuth>
                        <BootcampOrderFinal />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="orderhistory"
                    element={
                      <RequiredAuth>
                        <Orderhistory />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="affiliates"
                    element={
                      <RequiredAuth>
                        <Affiliates />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <RequiredAuth>
                        <Profile />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="profile/dashboard/:leadId"
                    element={
                      <RequiredAuth>
                        <Dashboard />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="dashboard/bootcamps"
                    element={
                      <RequiredAuth>
                        <UserBootcampDashboard />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="transaction"
                    element={
                      <RequiredCoach>
                        <TransactionDetails />
                      </RequiredCoach>
                    }
                  />
                  <Route
                    path="coachdashboard"
                    element={
                      <RequiredCoach>
                        <CoachDashboard />
                      </RequiredCoach>
                    }
                  />
                  <Route
                    path="coachdashboard/r6"
                    element={
                      <RequiredCoach>
                        <CoachBatches />
                      </RequiredCoach>
                    }
                  />
                  <Route
                    path="coachdashboard/bootcamps/:bootcampId"
                    element={
                      <RequiredAuth>
                        <CoachBootcampDashboard />
                      </RequiredAuth>
                    }
                  />
                  <Route
                    path="coachgig"
                    element={
                      <RequiredCoach>
                        <CoachGig />
                      </RequiredCoach>
                    }
                  />
                  <Route path="orders/batch/:leadId" element={<BatchOrder />} />
                  <Route path="becomecoach" element={<BecomeCoach />} />
                  <Route
                    path="/chessClassPayment"
                    element={<SchoolOrder590 />}
                  />
                  <Route
                    path="becomecoachForm"
                    element={
                      <RequiredNormalUser>
                        <BecomeCoachForm />
                      </RequiredNormalUser>
                    }
                  />
                  <Route path="contactus" element={<ContactUs />} />
                  <Route
                    path="reset-password/:id"
                    element={<ResetPassword />}
                  />
                  {/* <Route
                    path="createbootcamp"
                    element={
                      <RequiredAdmin>
                        <CreateBootCamp />
                      </RequiredAdmin>
                    }
                  /> */}
                  <Route
                    path="createbootcamp"
                    element={
                      <RequiredAdmin>
                        <AdminBootCamp />
                      </RequiredAdmin>
                    }
                  />
                  <Route
                    path="all1v1order"
                    element={
                      <RequiredAdmin>
                        <All1v1order />
                      </RequiredAdmin>
                    }
                  />
                  <Route path="termsandconditions" element={<Terms />} />
                  <Route path="privacypolicy" element={<Privacy />} />
                  <Route path="refund" element={<Refund />} />
                  <Route path="aboutus" element={<AboutUs />} />
                  <Route path="match" element={<Matchhistory />} />
                  <Route
                    path="tournament-details/:id?"
                    element={<TournamentInfo />}
                  />
                </Route>
                <Route
                  path="/playerorbit_pune_checkmate_challenge"
                  element={
                    <Navigate to="/tournament-details/67179641ab80d14181d3c3f1" />
                  }
                />
                <Route
                  path="/oclf"
                  element={
                    <Navigate to="/tournament-details/673f7e5bd7d98373563f1c53" />
                  }
                />
                    <Route
                  path="/popccpuzzle"
                  element={
                    <Navigate to="/tournament-details/673f7e5bd7d98373563f1c53" />
                  }
                />
                <Route path="/arvr" element={<RedirectToInstagram />} />
                <Route path="/meet/:roomId" element={<MainMeetPage />} />
                <Route path="/meet" element={<MainMeetPage />} />
              </Routes>
            </Suspense>
          </GlobalContextProvider>
        </BrowserRouter>
      </PayPalScriptProvider>
    </>
  );
}

export default App;
